import { render, staticRenderFns } from "./TaskDialog.vue?vue&type=template&id=395ab386&scoped=true&"
import script from "./TaskDialog.js?vue&type=script&lang=js&"
export * from "./TaskDialog.js?vue&type=script&lang=js&"
import style0 from "./TaskDialog.scss?vue&type=style&index=0&id=395ab386&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../Users/kcger/AppData/Roaming/npm/node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "395ab386",
  null
  
)

export default component.exports